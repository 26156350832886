
/***
 * GERAL
 ***/
$(function() {
    $('.animsition').animsition().one('animsition.inStart',function(){
        App.SetToggle();
        App.SetGaleria();
        App.SetBanner();
        App.SetCarouselMobile();
        App.SetValidate();
        App.SetMaskInput();
        App.SetInputError();
        App.SetFormSuccess();
        App.SetAnimatescroll(150);
        App.SetWow(200);

        $('html').addClass('loaded');

        // Carousel home
        $('.Topicos .itens').on('changed.owl.carousel', function(event) {
            $('.Topicos .tabs .tab').removeClass('_active');

            var current = event.item.index;
            var hash    = $(event.target).find('.owl-item').eq(current).find('.tab-item').data('owl-hash');

            $('.Topicos .tabs .' + hash).addClass('_active');
        });

        $(document).on('click', '.Topicos .tabs .tab', function(event) {
            event.preventDefault();
            var linkItem = $(this).data('target');
            var posi     = $('.owl-item .tab-item[data-owl-hash="'+linkItem+'"]').data('position');
            $('.Topicos .itens').trigger('to.owl.carousel', [posi, 300]);
        });

        // Carousel videos
        $('[data-component=carousel-videos]').each(function(index, el) {
            $(this).addClass('owl-carousel');
            $(this).owlCarousel({
                loop            : true,
                items           : 1,
                autoplay        : false,
                nav             : true,
                animateOut      : 'fadeOut',
                navText         : ['<i class="icon-left"></i>', '<i class="icon-right"></i>'],
                dots            : true,
                dotsData        : true
            });
        });

        // Carousel publicacoes
        $('[data-component=carousel-books]').each(function(index, el) {
            $(this).addClass('owl-carousel');
            $(this).owlCarousel({
                loop            : true,
                items           : 3,
                autoplay        : true,
                nav             : true,
                animateOut      : 'fadeOut',
                navText         : ['<i class="icon-left"></i>', '<i class="icon-right"></i>'],
                dots            : false,
                margin          : 50,
                center          : true,
                responsive      : {
                    0: {
                        items: 1,
                        center: false
                    },
                    480: {
                        items: 2,
                        margin: 20,
                        center: false
                    },
                    768: {
                        items: 3
                    }
                }
            });
        });

        // Open videos popup
        $('.open-video').magnificPopup({
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });

        // Parallax
        $(window).stellar({
            horizontalScrolling: false,
            positionProperty: 'transform',
        });

        // AOS
        AOS.init();
    });
});